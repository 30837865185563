/* TabStyles.css */
.custom-tabs .nav-tabs {
    border-bottom: 2px solid #ddd;
  }
  
  .custom-tabs .nav-link {
    color: #555;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  
  .custom-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #ddd;
  }
  
  .custom-tabs .nav-link.active {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff #007bff #ddd;
  }
  
  .custom-tabs .tab-content {
    padding: 1rem;
    border: 1px solid #ddd;
    border-top: none;
    border-radius: 0.25rem;
    background-color: #f9f9f9;
  }
  