.modalScrollable {
    max-height: 600px;
    overflow-y: auto;
  }
  
  .card-deck {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .card {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    width: 15rem; /* Ensure all cards have the same width */
  }
  
  .card.selected {
    border: 2px solid #007bff;
    box-shadow: 0 0 15px rgba(0, 123, 255, 0.5);
  }
  
  .card-img-top {
    max-width: 100%;
    margin-bottom: 10px;
    height: 150px;
    object-fit: cover;
  }
  
  .card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .card-title {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .card-text {
    margin-bottom: 10px;
    flex-grow: 1;
  }
  
  .card-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.campaign-name-label {
    font-weight: bold;
    font-size: 2rem; /* Large heading size */
  }
  
  .campaign-name-input {
    font-size: 1.2rem; /* Larger font size for input */
    padding: 1rem; /* Increase padding for a larger input field */
  }
  