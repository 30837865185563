.sidebar h3 {
    padding-left: 15px;
    padding-bottom: 10px;
  }
  
  .sidebar .nav-link {
    color: rgb(31, 36, 88);
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  
  .sidebar .nav-link svg {
    margin-right: 10px;
  }
  
  .sidebar .submenu .nav-link {
    padding-left: 30px;
  }
  
  .sidebar .nav-link.active {
    background-color: #282a58;
  }
  
  .sidebar .submenu {
    padding-left: 20px;
  }
  
  .sidebar .submenu .nav-link {
    font-size: 14px; /* Slightly smaller to differentiate from main menu items */
  }
  
  .sidebar .nav-link svg {
    margin-right: 10px;
  }
  
  .sidebar .nav-link .arrow-icon {
    margin-left: auto; /* Pushes the icon to the right */
  }

  .sidebar h1 {
    align-items: center;
    padding-left: 15px;
    padding-bottom: 10px;
    padding-top: 20px;
  }

  
  
  