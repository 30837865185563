body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.message-snippet {
  position: relative;
  cursor: pointer;
}

.message-tooltip {
  visibility: hidden;
  width: 500px;
  background-color: white;
  color: black;
  text-align: left;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Example shadow */
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: -250px; /* Half of the tooltip's width */
  opacity: 0;
  transition: opacity 0.3s;
}

.message-snippet:hover .message-tooltip {
  visibility: visible;
  opacity: 1;
}


.read-only-textarea {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  min-height: 80px; /* Adjust as needed */
  overflow-y: auto;
  background-color: #e9ecef; /* Readonly look */
}

@font-face {
  font-family: 'PremiumUltra23';
  src: url('../fonts/PremiumUltra23.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-size: 22px;
}
.myCustomFontClass {
  font-family: 'PremiumUltra23.ttf';
  font-size: 22px;
}

/* AccountPage.css */
.shadow-box {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  padding: 20px;
  margin-bottom: 30px;
  background: white;
  border-radius: 5px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Adds shadow */
  border: 1px solid #1b3559; /* Example blue border */
}

.table-bordered {
  font-size: 14px;
}
.btn {
  font-size: 14px;
}

.btn-primary {
  background-color: #1b3559;
}

.btn-primary:hover {
  background-color: #30599c;
}

.btn-primary:disabled {
  background-color: #48689b;
}

td, th {
  text-align: center;
}

.clickable-card:hover {
  box-shadow: 5px 5px 5px grey;
}

.clickable-card {
  cursor: pointer;
}

.warning {
  background-color: orange;
}

* {
  font-size: 14px;
}

#visitorChart {
  margin-top: 20px;
}
