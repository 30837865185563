  .custom-navbar {
    background-color: rgb(202, 228, 231); /* White background */
    box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.2, -2em,1);
    height: 90px; /* Larger height, adjust as needed */
    font-family: 'Poppins', sans-serif;
    font-weight: 500; /* Semi-light */
    font-size: 20px;
    color: rgb(255, 255, 255); /* RGB color for text */

  }
  
  .custom-navbar .navbar-brand,
  .custom-navbar .nav-link {
    color: rgb(255, 255, 255); /* RGB color for text */
  }
  
  /* Style for the navbar toggler icon (hamburger menu) */
  .custom-navbar .navbar-toggler-icon {
    filter: brightness(0) invert(1); /* Make the icon white or light for visibility */
  }
  
