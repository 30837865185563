.custom-quill {
    min-height: 500px; /* Minimum height for the rich text editor */
  }
  
  .char-count {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
  }
  

@font-face {
  font-family: 'PremiumUltra23';
  src: url('../fonts/PremiumUltra23.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-size: 25px;
}

@font-face {
  font-family: 'PremiumUltra27';
  src: url('../fonts/PremiumUltra27.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-size: 25px;
}

@font-face {
  font-family: 'PremiumUltra52';
  src: url('../fonts/PremiumUltra52.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-size: 25px;
}

@font-face {
  font-family: 'PremiumUltra66';
  src: url('../fonts/PremiumUltra66.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-size: 25px;
}

@font-face {
  font-family: 'PremiumUltra11';
  src: url('../fonts/PremiumUltra11.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-size: 25px;
}
.react-quill {
  /* Set the font family */
  font-family: 'PremiumUltra23', sans-serif;
  font-size: 30px;
}
