.card {
    background-color: #f8f9fa; /* Light background */
}

.form-control {
    background-color: white; /* Ensures form control stands out */
}

.table-responsive {
    overflow-x: auto;
}

.custom-container {
    width: 100%; /* Or any specific width */
    padding: 20px; /* Adjust padding if needed */
}

.warning {
    background-color: orange;
}

.selected-row {
    background-color: #30599c;
  }
