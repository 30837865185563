.a4-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align to top for natural downward expansion */
    background-color: #f0f0f0;
    padding: 20px;
    min-height: 100vh;
  }
  
  .a4-page {
    width: 210mm; /* A4 width */
    min-height: 297mm; /* Allow page to grow beyond A4 size if needed */
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    overflow: visible; /* Allow overflow for expanding content */
    padding-bottom: 20px; /* Extra space for content */
  }
  
  .editable-text {
    font-family: var(--fontStyle, 'PremiumUltra23'); /* Default font */
    font-size: var(--fontSize, 20px); /* Default font size */
    color: #000080;
    padding: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    white-space: pre-wrap;
}

.right-text {
    text-align: right;
    position: absolute;
    top: 40px;
    right: 40px;
    width: 300px;
}


  
  .left-text {
    text-align: left;
  }
